<template>
    <div>
        <v-col>
            <v-text-field
                v-model=phone
                label="Ingrese el número"
                prepend-inner-icon="mdi-cellphone-iphone"
            ></v-text-field>
        </v-col>
        <v-col class="text-center pt-0">
            <v-btn 
                color="primary"
                @click=storePhone
                small
            > 
                <v-icon>mdi-content-save</v-icon> Guardar 
            </v-btn>
            <v-btn 
                color="default"
                @click=close
                small
            > 
                <v-icon>mdi-cancel</v-icon> Cerrar 
            </v-btn>
        </v-col>
    </div>
</template>

<script>
import {mapState, mapActions,mapMutations} from 'vuex'

export default {
    name:'CapturePhone',
    data: () => ({
        phone:null
    }),
    computed:{

        ...mapState('pos',['partyIdClient','quoteId'])

    },
    methods:{

        ...mapMutations('master',['mapMutations','setUrl']),

        ...mapActions('master',['requestApi']),

        storePhone(){
           
            this.setUrl('datos-person')
            this.requestApi({
                method: 'POST',
                data:{
                    telefono: this.phone,
                    partyId: this.partyIdClient,
                    quoteId : this.quoteId
                }
            }).then(res => {
                this.$emit('storePhone',{res:res,tlf:this.phone})
                this.close()
            })

        },

        close(){
            this.phone=null
            this.$emit('close',false)
        }
    }
}
</script>

<style>

</style>